import React from 'react'
import { CSSReset, theme, ThemeProvider } from '@chakra-ui/core'
import { Scrollbars } from 'react-custom-scrollbars'
import GlobalStyles from './components/styles/Global'

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    translucent: '#8A8A8A26',
    f8: '#5ac9c8',
  },
  fonts: {
    body: 'Ubuntu Mono, monospace, sans-serif',
    heading: 'Satisfy, serif',
    mono: 'Ubuntu Mono, monospace',
  },
  icons: {
    ...theme.icons,
    lightbulb: {
      path: (
        <g fill="currentColor">
          <path d='M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6C7.8 12.16 7 10.63 7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z' />
        </g>
      ),
    },
    cloud: {
      path: (
        <g fill="currentColor">
          <path d='M416.5,191.5 C416.5,226.844 387.844,255.5 352.5,255.5 L352.5,256 L80.5,256 C36.312,256 0.5,220.187 0.5,176 C0.5,137.25 28.063,104.937 64.656,97.594 C64.656,97.063 64.5,96.563 64.5,96 C64.5,42.969 107.469,0 160.5,0 C197.938,0 230,21.625 245.844,52.875 C254.219,49.937 263.094,48 272.5,48 C316.344,48 351.844,83.25 352.406,127 L352.5,127 L352.5,127.5 C387.844,127.5 416.5,156.156 416.5,191.5 Z' />
        </g>
      ),
      viewBox: "0 0 417 256",
    },
  },
}

const scrollTrackStyle = {
  backgroundColor: 'transparent',
  right: '5px',
  bottom: '5px',
  top: '5px',
  width: '5px',
}
const scrollThumbStyle = {
  width: '7px',
  borderRadius: '5px',
  backgroundColor: '#5ac9c8',
}

export default ({ children }) => {
  
  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <GlobalStyles />
      <Scrollbars style={{height:'100vh'}}
        autoHide
        renderTrackVertical={({style, ...props}) =>
          <div {...props} style={{...style, ...scrollTrackStyle}}/>
        }
        renderThumbVertical={({style, ...props}) =>
          <div {...props} style={{...style, ...scrollThumbStyle}}/>
        }
      >
        {children}
      </Scrollbars>
    </ThemeProvider>
  )
}