import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root {
    --teal: rgb(129, 230, 217);
  }
  html {
    font-family: 'Ubuntu Mono', monospace, sans-serif;
    box-sizing: border-box;
    margin: 0 auto;
    &:after {
      box-sizing: content-box;
    }
    &:before {
      box-sizing: content-box;
    }
  }
  body {
    font-family: 'Ubuntu Mono', monospace, sans-serif;
    min-height: calc(100vh - 40px);
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  a {
    color: rgb(129, 230, 217);
  }

  .satisfy {
    font-family: 'Satisfy', cursive;
  }
  
  /* typography */
  h1,h2,h3,h4,h5,h6 {
    line-height: 1.5;
  }
  .responsive {
    font-size: 1.25em;
    @media (max-width: 1000px) { font-size: 1.1em; }
    @media (max-width: 800px)  { font-size: 1.0em; }
    @media (max-width: 600px)  { font-size: .90em; }
    @media (max-width: 500px)  { font-size: .85em; }
    @media (max-width: 400px)  { font-size: .80em; }
  }

  /* generics */
  .action {
    cursor: pointer;
    &:hover, &:active {
      transform: scale(1.4);
      transition: 1s;
    }
  }

  /* shapes */
  .infinity {
    position: relative;
    width: 212px;
    height: 100px;
    box-sizing: content-box;
  }
  .infinity:before,
  .infinity:after {
    content: "";
    box-sizing: content-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    border: 20px solid #5ac9c8;
    border-radius: 50px 50px 0 50px;
    transform: rotate(-45deg);
  }
  .infinity:after {
    left: auto;
    right: 0;
    border-radius: 50px 50px 50px 0;
    transform: rotate(45deg);
  }

  .burst {
    background: yellow;
    width: 120px;
    height: 120px;
    position: relative;
    text-align: center;
  }
  .burst:before,
  .burst:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 120px;
    width: 120px;
    background: yellow;
  }
  .burst:before {
    transform: rotate(30deg);
  }
  .burst:after {
    transform: rotate(60deg);
  }

  /* responsive */
  .responsive-font-size {
    font-size: 1em;
    @media (max-width: 1400px) { font-size: .95em; }
    @media (max-width: 1200px) { font-size: .90em; }
    @media (max-width: 1000px) { font-size: .85em; }
    @media (max-width: 800px)  { font-size: .80em; }
    @media (max-width: 600px)  { font-size: .75em; }
    @media (max-width: 500px)  { font-size: .70em; }
    @media (max-width: 400px)  { font-size: .65em; }
  }
  .no-mobile {
    @media (max-width: 800px) { display: none; }
  }
`;
export default GlobalStyles;
